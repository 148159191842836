<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="550" min-height="550" showFooter
               esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="typeId" label="产品类型">
            <el-select v-model="data.typeId" placeholder="选择产品类型" size="mini"
                       style="width: 100%;" @change="changeType()">
              <el-option v-for="(item, index) in productType" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="productId" label="产品">
            <el-select v-model="data.productId" placeholder="选择产品" size="mini" style="width: 100%;" filterable>
              <el-option v-for="(item, index) in product" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="inputTime" label="化验时间">
            <el-date-picker style="width: 100%" v-model="data.inputTime" type="datetime" placeholder="选择化验时间">
            </el-date-picker>
          </el-form-item>
          <template v-for="(item,index) in assayProduct">
            <el-form-item :prop="item.name" :label="item.label" :key="index">
              <el-input v-model="data[item.name]" :placeholder="item.placeholder" type="number"></el-input>
            </el-form-item>
          </template>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      ids: [],
      rules: {},
      loading: false,
      assayProduct: [],
      productType: [],
      product: []
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.name = data.name
      this.productType = data.productType
      this.product = data.product
      this.assayProduct = []
      this.rules = {
        typeId: [{required: true, trigger: 'change', message: '请选择产品类型'}],
        productId: [{required: true, trigger: 'change', message: '请选择产品'}],
        inputTime: [{required: true, trigger: 'change', message: '请选择化验时间'}],
      }
    },
    getRules() {
      let typeName
      this.productType.forEach(item => {
        if (item.id === this.data.typeId) typeName = item.name
      })
      console.log(typeName)
      this.assayProduct.forEach(item => {
        item['placeholder'] = `输入${item.label}`
        if (!['g', 'shimo', 'x', 'y'].includes(item.name) && typeName !== '样品') {
          this.$set(this.rules, item.name, [{required: true, trigger: 'blur', message: item['placeholder']}])
        } else if (typeName === '样品') {
          this.$set(this.rules, item.name, [{required: false, trigger: 'blur', message: item['placeholder']}])
        }
      })
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.clearValidate()
      })
    },
    changeType() {
      this.$axios.get(`/order/assay/item/type/${this.data.typeId}`).then(response => {
        if (response){
          this.assayProduct = this.$utils.sortBy(response.data.data, [['sort', 'asc']])
          this.getRules()
        }
      })
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          let assayNameList = []
          this.assayProduct.forEach(item => {
            assayNameList.push(item.name)
          })
          const assayParams = this.$utils.pick(this.data, assayNameList)
          const params = {
            productId: this.data.productId,
            inputTime: this.data.inputTime,
            typeId: this.data.typeId,
            params: assayParams
          }
          this.$axios.post('/order/assay/create/sample', params).then((res) => {
              if (res) {
                this.$message.success('新增样品化验单成功')
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch((err) => {
              console.log('新增样品化验单失败', err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
