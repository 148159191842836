<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl">
            <el-button size="mini" type="primary" class="el-icon-plus" @click="addAssay">新增化验单</el-button>
            <el-button size="mini" type="primary" class="el-icon-plus" @click="addNoAssay" style="margin-right: 10px">新增样品化验单</el-button>
            <el-select v-model="search.type" clearable filterable placeholder="选择化验类型" size="mini"
                       style="width: 120px; margin-right: 10px" @change="searchInit()">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="search.typeId" clearable filterable placeholder="选择产品类型" size="mini"
              style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in productType" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.productId" clearable filterable placeholder="选择产品" size="mini"
              style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in product" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-date-picker @change="dateValue(search.value)" v-model="search.value" type="datetimerange" size="mini"
              value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
              :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间" class="el-daterange-picker-radius"
              style="width: 280px">
            </el-date-picker>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="查询" placement="top">
              <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
              <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="assay" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow
      @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="code" min-width="160" title="化验编号"></vxe-table-column>
      <vxe-table-column field="type" min-width="80" title="化验类型"></vxe-table-column>
      <vxe-table-column field="supplierName" min-width="160" title="客户/供应商"></vxe-table-column>
      <vxe-table-column field="productName" min-width="150" title="产品"></vxe-table-column>
      <vxe-table-column field="typeName" min-width="150" title="产品类型"></vxe-table-column>
      <vxe-table-column field="carNum" min-width="80" title="化验车数"></vxe-table-column>
      <vxe-table-column field="inputTime" min-width="180" title="化验时间"></vxe-table-column>
      <template v-for="(item, index) in assayList">
        <vxe-table-column :field="item.name" :title="item.label" :key="index" min-width="120"></vxe-table-column>
      </template>
      <vxe-table-column fixed="right" align="center" width="120" title="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-view" @click="getCheck(scope.row)">查看</el-button>
          <el-button type="text" size="mini" icon="el-icon-edit" @click="getUpdate(scope.row)">编辑
          </el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
      :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
      <template v-slot:left>
        <div class="fl footer-check">
          <p>
            已选
            <b style="font-size: 10px">{{ checkLength }}</b> 条
          </p>
        </div>
      </template>
    </vxe-pager>
    <check ref="check" @init="init"/>
    <detail ref="detail" @init="init" />
    <add-detail ref="addDetail" @init="init" />
  </div>
</template>

<script>
import check from './components/check'
import detail from './components/detail'
import addDetail from "./components/addDetail"
import { mapGetters } from 'vuex'

export default {
  name: 'assay',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 190 + 'px',
      allAlign: 'center',
      loading: false,
      checkBox: [],
      checkLength: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      search: {},
      isNull: ['', null, undefined],
      assayList: [],
      typeList: [
        {value: 'Purchase', label: '采购'},
        {value: 'Sell', label: '销售'},
        {value: 'Sample', label: '样品'},
      ]
    }
  },
  created() {
    this.$axios.get('/order/assay/item/all').then(res => {
      if (res) {
        this.assayList = res.data.data
        this.init()
      }
    })
  },
  components: { check, detail, addDetail },
  computed: { ...mapGetters(['product', 'productType'])},
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.assay) {
        this.tableHeight = window.innerHeight - this.$refs.assay.$el.offsetTop - 62 + 'px'
      }
    }
    this.$store.dispatch('getProductType')
    this.$store.dispatch('getProduct')
  },
  methods: {
    init() {
      this.loading = true
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        },
      }
      if (!this.isNull.includes(this.search.productId)) params.params.productId = this.search.productId
      if (!this.isNull.includes(this.search.typeId)) params.params.typeId = this.search.typeId
      if (!this.isNull.includes(this.search.type)) params.params.type = this.search.type
      if (!this.isNull.includes(this.search.value)) {
        params.params.startTime = this.search.startTime
        params.params.endTime = this.search.endTime
      }
      this.$axios
        .get('/order/assay/page', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            this.data = data.list
            this.data.forEach(item => {
              for (var key in item.params) {
                item[key] = item.params[key]
              }
            })
            this.page.currentPage = data.pageNum
            this.page.totalResult = data.total
            this.checkBox = []
            this.checkLength = 0
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.assay.openExport()
    },
    printDataEvent() {
      this.$refs.assay.openPrint()
    },
    getCheck(row) {
      this.$axios.get(`/order/assay/detail/${row.id}`).then((res) => {
        if (res) {
          this.$axios.get(`/order/assay/item/type/${row.typeId}`).then(response => {
            if (response){
              const dataValue = res.data.data
              for (var key in dataValue.params) {
                dataValue[key] = dataValue.params[key]
              }
              const data = {
                data: dataValue,
                assayProduct: response.data.data,
              }
              this.$refs.check.display(data)
            }
          })
        }
      })
    },
    getUpdate(row) {
      this.$axios.get(`/order/assay/detail/${row.id}`).then((res) => {
        if (res) {
          this.$axios.get(`/order/assay/item/type/${row.typeId}`).then(response => {
            if (response){
              const dataValue = res.data.data
              for (var key in dataValue.params) {
                dataValue[key] = dataValue.params[key]
              }
              const data = {
                data: dataValue,
                assayProduct: response.data.data,
                productType: this.productType,
                isAdd: false,
                name: '修改化验单'
              }
              this.$refs.detail.display(data)
            }
          })
        }
      })
    },
    /** 选择条数 */
    changeBoxChange() {
      this.checkBox = this.$refs.assay.getCheckboxRecords()
      this.checkLength = this.$refs.assay.getCheckboxRecords().length
    },
    /** 条数更改 */
    handlePageChange({ currentPage, pageSize }) {
      const _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    searchInit() {
      this.page.currentPage = 1
      this.init()
    },
    dateValue(value) {
      if (value) {
        this.$set(this.search, 'startTime', value[0])
        this.$set(this.search, 'endTime', value[1])
      }
    },
    addAssay() {
      let isAssay = true
      if (this.checkLength === 0) {
        this.$message.warning('请选择一条化验单')
        isAssay = false
      } else if (this.checkLength > 1) {
        this.$message.warning('请选择一条化验单')
        isAssay = false
      }
      if (isAssay) {
        const data = {data: this.checkBox[0], name: '新增化验单', isAdd: true, assayProduct: [], productType: this.productType}
        this.$refs.detail.display(data)
      }
    },
    addNoAssay() {
      const data = {data: {}, name: '新增样品化验单', product: this.product, productType: this.productType}
      this.$refs.addDetail.display(data)
    }
  },
}
</script>

<style scoped></style>
